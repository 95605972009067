import { flatMap, getOr, includes, map } from 'lodash/fp';

import getFutureDate from '../get-future-date';
import isProductBestSeller from '../is-product-best-seller';
import PriceService, { PriceData, Prices } from '../../models/price';
import UrlService from '../../models/url';

type prepareProductForProductTileProps = {
  customer: PriceData;
  frequency: string;
  prices: Prices;
  mlsAmount?: number | null;
  useFutureEffectiveDate: boolean;
};

// @ts-expect-error - Automatic, Please fix when editing this file
const serviceMapper = (feature) => map(({ id, name }) => ({ id, name }), feature.services);

const prepareProductForProductTile =
  ({ customer, frequency, prices, mlsAmount, useFutureEffectiveDate }: prepareProductForProductTileProps) =>
  // @ts-expect-error - Automatic, Please fix when editing this file
  (product) => {
    if (!product) {
      return null;
    }

    const {
      id,
      additionalDetails,
      charges,
      content,
      ctaText,
      extras,
      features,
      hospital,
      hospitalTier,
      name,
      rank,
      type,
      ctaProductId,
      parentProductId,
      unavailableWithExcessInfo,
    } = product;

    const { scale } = customer;

    const date = getFutureDate(useFutureEffectiveDate);

    const price = PriceService.getPrice(prices, id, customer, { date });

    const priceObject = price ? PriceService.getAmountAsObject(price, frequency) : undefined;
    const saving =
      mlsAmount && priceObject
        ? Math.max(mlsAmount - parseFloat(`${priceObject.dollars}.${priceObject.cents}`), 0)
        : undefined;

    const hospitalFeatured = getOr([], 'hospital.featured', product);
    const hospitalServices = flatMap(serviceMapper, hospitalFeatured);

    return {
      additionalDetails,
      charges,
      content,
      ctaText,
      extras,
      features,
      frequency,
      hospital,
      hospitalTier,
      id,
      isAvailable: includes(scale, product.scales.supported),
      isBestSeller: isProductBestSeller({ parentProductId, productId: id, productType: type, scale }),
      name,
      price: priceObject,
      rank,
      saving,
      scale: customer.scale,
      services: hospitalServices,
      type,
      unavailableWithExcessInfo,
      url: UrlService.getProductUrl(ctaProductId || id),
    };
  };

export default prepareProductForProductTile;
export { serviceMapper };
