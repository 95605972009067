import { Col, Row, Section } from '@ahmdigital/ui';
import styled from 'styled-components';

export const StyledSection = styled(Section)`
  padding: 2.5rem 0 3.5rem;

  ${({ theme }) => `
    @media (min-width: ${theme.media.sm}) {
      padding: 2.5rem 2rem;
    }

    @media (min-width: ${theme.media.md}) {
      max-width: 55rem;
      padding: 6rem 2rem;
    }
  `}

  .flair {
    .ctaLink {
      color: $color-purple;
    }
  }

  /* offer section */
  section {
    padding-top: 1rem;
  }
`;

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => `
    @media (min-width: ${theme.media.md}) {
      flex-direction: row;
    }
  `}
`;

export const DesktopVideoWrapper = styled.div<{ $extraTopMargin?: boolean }>`
  display: none;

  ${({ theme, $extraTopMargin }) => `
    @media (min-width: ${theme.media.md}) {
      margin-top: ${$extraTopMargin ? '13rem' : '5rem'};
      margin-bottom: 5rem;
      display: block;
      text-align: center;
      width: 45%;
    }
  `}
`;

export const Cta = styled(Row)<{ $extraTopMargin?: boolean }>`
  ${({ theme, $extraTopMargin }) => `
    @media (max-width: ${theme.media.md}) {
      margin-top: ${$extraTopMargin ? '3rem' : 0};
    }
  `}
`;
